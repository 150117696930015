import React from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout/layout";
import ArticlePreview from "../components/article-preview";
import { useStaticQuery, graphql } from "gatsby";
import "../css/main.css";
import IndexBody from "../components/indexBody";

const Index = () => {
  const query = useStaticQuery(graphql`
    query HomeQuery {
      contentfulAsset(title: { in: "silber-flipping-off" }) {
        fluid(maxWidth: 1980, maxHeight: 1280) {
          ...GatsbyContentfulFluid_tracedSVG
        }
        title
      }
      allContentfulAsset(limit: 50) {
        edges {
          node {
            fluid(maxWidth: 1250, maxHeight: 1500) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            title
          }
        }
      }
      allContentfulHeaderPhotos {
        edges {
          node {
            photos {
              fluid(maxWidth: 1980, maxHeight: 1280) {
                ...GatsbyContentfulFluid_tracedSVG
              }
              title
            }
          }
        }
      }
    }
  `);


  // const posts = query.allContentfulBlogPost.edges;
  // const [author] = query.allContentfulPerson.edges;
  const silber = query.contentfulAsset;
  const pictures = query.allContentfulHeaderPhotos.edges[0].node.photos

  return (
    <Layout>
      <div style={{ background: "#fff" }}>
        <Helmet title="SD Roast" />
        <Hero data={pictures} />

        <IndexBody silber={silber} />

        {/* <IndexBody />
        <div className="wrapper">
          <h2 className="section-headline">Recent articles</h2>
          <ul className="article-list">
            {posts.map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>
    </Layout>
  );
};

export default Index;
