import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import "swiper/components/navigation/navigation.min.css"
import SwiperCore, {
  Navigation
} from 'swiper/core';
import Img from "gatsby-image";
import { Link } from "gatsby";

const Body = ({pictures, silber}) => {

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
  const randomArray = (max) => {
    return [getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max)]
  }


  return (
    <div>
      <section className="about indexSection">
        {/* <h2 className="headline">How it Started</h2> */}
        <h2 className="headline">How This Party Started</h2>
        <div className="indexSection-container">
          <p>
            In the fall of 2013 during the hustle and bustle of End of Year Holiday Parties, Award Banquets and bullshit…. 
            Frank & RJ said, “Let’s do something different…. Let’s  hold off and wait to have a party where we can celebrate the past year, 
            have an adults-only prom-like party with an awards ceremony over a nice dinner and make it special” and so the tradition began. 
            In January of 2014, <span className='theRoast' style={{paddingRight: '8px', fontSize: '22px'}} >The San Diego Roast</span>was born. 
            <br />
            <br />
            With the help of some key contributors like Toby Harris, Steve Barker and Ryan Lex… 
            7 years later and counting, <span className='theRoast' style={{paddingRight: '8px', fontSize: '22px'}} > The Roast</span> has become the hottest, non-company event that everyone wants to experience.
            This annual event is an invite only and “What happens at
             <span className='theRoast' style={{paddingRight: '4px', fontSize: '22px'}} > The Roast</span>, stays 
             at <span className='theRoast' style={{paddingRight: '4px', fontSize: '22px'}} >The Roast</span>”

            {/* In the winter of 2013 RJ and Frank Thomas said fuck it, let's do something different. Between the ceaseless award ceremonies giving away "Top Producer" 
            awards like they're participation trophies, and enough ugly sweater parties to make you feel like you'll never get the festive glitter out of your lint trap, it was time for change.
            <br />
            <br />
            They came up with The Roast; an annually hosted invite only party that has a little bit of everything. Dinner, drinks, and live music are all taken care of. 
            All you need to do is prepare to roast, and prepare to get roasted. */}
          </p>
          <div>
          <Swiper navigation={true} className="mySwiper" style={{maxWidth: '350px', padding: '.5rem'}}>
            <SwiperSlide style={{padding: '0 .5rem', width: '300px'}} >
            <iframe width="350" height="300" src="https://www.youtube.com/embed/sOrZ6LJsIuo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide style={{padding: '0 .5rem', width: '300px'}} >
            <iframe width="350" height="300" src="https://www.youtube.com/embed/RGQ4r9xul9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </SwiperSlide>
          </Swiper>
          </div>
        </div>
      </section>
      <section className="meatAndTaters indexSection">
      <h1 className="headline headline-red">A Time to Remember</h1>
      <div className="meatAndTaters-container">

        
        <div>
          {/* <Swiper navigation={true} className="mySwiper" style={{maxWidth: '350px', padding: '.5rem'}}>
          {pictures.map((asset, index, arr) => {
            if (randomArray(arr.length).includes(index) ) {
            return (
              <SwiperSlide style={{padding: '0 .5rem', width: '300px'}} >
                <Img style={{borderRadius: '20px', filter: 'grayscale(1)'}} alt={asset.node.title} fluid={asset.node.fluid} />
              </SwiperSlide>
            )
          }
          })}
          </Swiper> */}
            <Img style={{borderRadius: '20px', filter: 'grayscale(1)', width: '450px', maxWidth: '100%'}} alt={silber.title} fluid={silber.fluid} />

          </div>
          <div style={{display: 'flex', alignContent: 'center'}} >
            <p style={{height: 'fit-content', alignSelf: 'center', fontSize: '18px'}} >
              Dressed to the nines with bellies full of wine, we lovingly peer pressure each other into leaving it all on the podium. 
              {/* <br /><br /> */}
              {/* What happens at The Roast, stays at The Roast... Except for what you see here on our site 😉 */}
              </p>
          </div>
      </div>
      
      </section>
      <section className="contact indexSection">
        <h2 className="headline">Get on the Waiting List</h2>
        <div className="indexSection-container">
          {/* <p>Invitation or not, if you plan on attending then the time to RSVP is now!</p> */}
          <p><span className='theRoast' style={{paddingRight: '10px', fontSize: '30px'}} >The San Diego Roast</span>is a private party. This non-company affiliated event is by invitation only. 
            Please submit your name and information on the link provided and it will be reviewed by our Roast committee.</p>
          <div>
            <button className="cta cta-main"><Link style={{ textDecoration: "none" }} to="/rsvp">Let's Go!</Link></button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Body;
